// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
import { Dispatch, ActionCreator, Store } from 'redux';
import getCore from 'cvat-core-wrapper';
import { updateTaskSuccess } from './tasks-actions';
import logger, { LogType } from 'cvat-logger';
import { AnnotationActionTypes } from './annotation-actions';
import { getCVATStore } from 'cvat-store';
import { CombinedState } from 'reducers/interfaces';

const cvat = getCore();
let store: null | Store<CombinedState> = null;

function getStore(): Store<CombinedState> {
    if (store === null) {
        store = getCVATStore();
    }
    return store;
}

export enum ReviewActionTypes {
    INITIALIZE_REVIEW_SUCCESS = 'INITIALIZE_REVIEW_SUCCESS',
    INITIALIZE_REVIEW_FAILED = 'INITIALIZE_REVIEW_FAILED',
    CREATE_ISSUE = 'CREATE_ISSUE',
    START_ISSUE = 'START_ISSUE',
    FINISH_ISSUE_SUCCESS = 'FINISH_ISSUE_SUCCESS',
    FINISH_ISSUE_FAILED = 'FINISH_ISSUE_FAILED',
    CANCEL_ISSUE = 'CANCEL_ISSUE',
    RESOLVE_ISSUE = 'RESOLVE_ISSUE',
    RESOLVE_ISSUE_SUCCESS = 'RESOLVE_ISSUE_SUCCESS',
    RESOLVE_ISSUE_FAILED = 'RESOLVE_ISSUE_FAILED',
    REOPEN_ISSUE = 'REOPEN_ISSUE',
    REOPEN_ISSUE_SUCCESS = 'REOPEN_ISSUE_SUCCESS',
    REOPEN_ISSUE_FAILED = 'REOPEN_ISSUE_FAILED',
    COMMENT_ISSUE = 'COMMENT_ISSUE',
    COMMENT_ISSUE_SUCCESS = 'COMMENT_ISSUE_SUCCESS',
    COMMENT_ISSUE_FAILED = 'COMMENT_ISSUE_FAILED',
    REMOVE_ISSUE_SUCCESS = 'REMOVE_ISSUE_SUCCESS',
    REMOVE_ISSUE_FAILED = 'REMOVE_ISSUE_FAILED',
    SUBMIT_REVIEW = 'SUBMIT_REVIEW',
    SUBMIT_REVIEW_SUCCESS = 'SUBMIT_REVIEW_SUCCESS',
    SUBMIT_REVIEW_FAILED = 'SUBMIT_REVIEW_FAILED',
    SWITCH_ISSUES_HIDDEN_FLAG = 'SWITCH_ISSUES_HIDDEN_FLAG',
    SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG = 'SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG',
    SWITCH_RESOLVED_ISSUES_FILTERED_FLAG = 'SWITCH_RESOLVED_ISSUES_FILTERED_FLAG',
}

function saveLogsAsync(logType: string, payload: any = {}): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>) => {
        const job = getStore().getState().annotation.job.instance;
        const frame = getStore().getState().annotation.player.frame.number;
        await job.logger.log(logType, { ...payload, frame });
        try {
            await logger.save();
            dispatch({
                type: AnnotationActionTypes.SAVE_LOGS_SUCCESS,
                payload: {},
            });
        } catch (error) {
            dispatch({
                type: AnnotationActionTypes.SAVE_LOGS_FAILED,
                payload: {
                    error,
                },
            });
        }
    };
}

export const reviewActions = {
    initializeReviewSuccess: (reviewInstance: any, frame: number) =>
        createAction(ReviewActionTypes.INITIALIZE_REVIEW_SUCCESS, { reviewInstance, frame }),
    initializeReviewFailed: (error: any) => createAction(ReviewActionTypes.INITIALIZE_REVIEW_FAILED, { error }),
    createIssue: () => createAction(ReviewActionTypes.CREATE_ISSUE, {}),
    startIssue: (position: number[]) =>
        createAction(ReviewActionTypes.START_ISSUE, { position: cvat.classes.Issue.hull(position) }),
    finishIssueSuccess: (frame: number, issue: any) =>
        createAction(ReviewActionTypes.FINISH_ISSUE_SUCCESS, { frame, issue }),
    finishIssueFailed: (error: any) => createAction(ReviewActionTypes.FINISH_ISSUE_FAILED, { error }),
    cancelIssue: () => createAction(ReviewActionTypes.CANCEL_ISSUE),
    commentIssue: (issueId: number) => createAction(ReviewActionTypes.COMMENT_ISSUE, { issueId }),
    commentIssueSuccess: () => createAction(ReviewActionTypes.COMMENT_ISSUE_SUCCESS),
    commentIssueFailed: (error: any) => createAction(ReviewActionTypes.COMMENT_ISSUE_FAILED, { error }),
    resolveIssue: (issueId: number) => createAction(ReviewActionTypes.RESOLVE_ISSUE, { issueId }),
    resolveIssueSuccess: () => createAction(ReviewActionTypes.RESOLVE_ISSUE_SUCCESS),
    resolveIssueFailed: (error: any) => createAction(ReviewActionTypes.RESOLVE_ISSUE_FAILED, { error }),
    reopenIssue: (issueId: number) => createAction(ReviewActionTypes.REOPEN_ISSUE, { issueId }),
    reopenIssueSuccess: () => createAction(ReviewActionTypes.REOPEN_ISSUE_SUCCESS),
    reopenIssueFailed: (error: any) => createAction(ReviewActionTypes.REOPEN_ISSUE_FAILED, { error }),
    submitReview: (reviewId: number) => createAction(ReviewActionTypes.SUBMIT_REVIEW, { reviewId }),
    submitReviewSuccess: () => createAction(ReviewActionTypes.SUBMIT_REVIEW_SUCCESS),
    submitReviewFailed: (error: any) => createAction(ReviewActionTypes.SUBMIT_REVIEW_FAILED, { error }),
    removeIssueSuccess: (issueId: number, frame: number) =>
        createAction(ReviewActionTypes.REMOVE_ISSUE_SUCCESS, { issueId, frame }),
    removeIssueFailed: (error: any) => createAction(ReviewActionTypes.REMOVE_ISSUE_FAILED, { error }),
    switchIssuesHiddenFlag: (hidden: boolean) => createAction(ReviewActionTypes.SWITCH_ISSUES_HIDDEN_FLAG, { hidden }),
    switchIssuesHiddenResolvedFlag: (hidden: boolean) =>
        createAction(ReviewActionTypes.SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG, { hidden }),
    switchIssuesHiddenFilteredFlag: (hidden: boolean, id: number) =>
        createAction(ReviewActionTypes.SWITCH_RESOLVED_ISSUES_FILTERED_FLAG, { hidden, id }),
};

export type ReviewActions = ActionUnion<typeof reviewActions>;

export const initializeReviewAsync = (): ThunkAction => async (dispatch, getState) => {
    try {
        const state = getState();
        const {
            annotation: {
                job: { instance: jobInstance },
                player: {
                    frame: { number: frame },
                },
            },
        } = state;

        const reviews = await jobInstance.reviews();
        const count = reviews.length;
        let reviewInstance = null;
        if (count && reviews[count - 1].id < 0) {
            reviewInstance = reviews[count - 1];
        } else {
            reviewInstance = new cvat.classes.Review({ job: jobInstance.id });
        }

        dispatch(reviewActions.initializeReviewSuccess(reviewInstance, frame));
    } catch (error) {
        dispatch(reviewActions.initializeReviewFailed(error));
    }
};

export const finishIssueAsync =
    (message: string): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            auth: { user },
            annotation: {
                player: {
                    frame: { number: frameNumber },
                },
            },
            review: { activeReview, newIssuePosition },
        } = state;

        try {
            const issue = await activeReview.openIssue({
                frame: frameNumber,
                position: newIssuePosition,
                owner: user,
                comment_set: [
                    {
                        message,
                        author: user,
                    },
                ],
            });
            await activeReview.toLocalStorage();
            dispatch(reviewActions.finishIssueSuccess(frameNumber, issue));
        } catch (error) {
            dispatch(reviewActions.finishIssueFailed(error));
        }
    };

export const commentIssueAsync =
    (id: number, message: string): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            auth: { user },
            review: { frameIssues, activeReview },
        } = state;

        try {
            dispatch(reviewActions.commentIssue(id));
            const [issue] = frameIssues.filter((_issue: any): boolean => _issue.id === id);
            await issue.comment({
                message,
                author: user,
            });
            if (activeReview && activeReview.issues.includes(issue)) {
                await activeReview.toLocalStorage();
            }
            dispatch(reviewActions.commentIssueSuccess());
            dispatch(saveLogsAsync(LogType.commentIssue));
        } catch (error) {
            dispatch(reviewActions.commentIssueFailed(error));
        }
    };

export const resolveIssueAsync =
    (id: number): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            auth: { user },
            review: { frameIssues, activeReview },
        } = state;

        try {
            dispatch(reviewActions.resolveIssue(id));
            const [issue] = frameIssues.filter((_issue: any): boolean => _issue.id === id);
            await issue.resolve(user);
            if (activeReview && activeReview.issues.includes(issue)) {
                await activeReview.toLocalStorage();
            }

            dispatch(reviewActions.resolveIssueSuccess());
            dispatch(saveLogsAsync(LogType.resolveIssue));
        } catch (error) {
            dispatch(reviewActions.resolveIssueFailed(error));
        }
    };

export const reopenIssueAsync =
    (id: number): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            auth: { user },
            review: { frameIssues, activeReview },
        } = state;

        try {
            dispatch(reviewActions.reopenIssue(id));
            const [issue] = frameIssues.filter((_issue: any): boolean => _issue.id === id);
            await issue.reopen(user);
            if (activeReview && activeReview.issues.includes(issue)) {
                await activeReview.toLocalStorage();
            }

            dispatch(reviewActions.reopenIssueSuccess());
            dispatch(saveLogsAsync(LogType.reopenIssue));
        } catch (error) {
            dispatch(reviewActions.reopenIssueFailed(error));
        }
    };

export const submitReviewAsync =
    (review: any): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            annotation: {
                job: { instance: jobInstance },
            },
        } = state;

        try {
            dispatch(reviewActions.submitReview(review.id));
            await review.submit(jobInstance.id);
            const [task] = await cvat.tasks.get({ id: jobInstance.task.id });
            const { issues, reviewer, status } = review;
            dispatch(updateTaskSuccess(task, jobInstance.task.id));
            dispatch(reviewActions.submitReviewSuccess());
            //logs
            let uniqueFramesWithIssue = new Set();
            const { review: jobReviews, annotation } = getState();
            const reviewIssues = jobReviews.issues;
            const activeReviewIssues = review.issues;
            const allIssues = [...reviewIssues, ...activeReviewIssues];
            const reviewId = jobReviews.reviews.length;
            const allFramesInJob = annotation.player.frameAngles.length;

            allIssues.map((issue: { frame: number }) => {
                uniqueFramesWithIssue.add(issue.frame);
            });

            const inaccuracy = (uniqueFramesWithIssue.size / allFramesInJob) * 100;

            issues.map((issue: any) => {
                dispatch(
                    saveLogsAsync(LogType.createIssue, {
                        reviewId,
                        issuesMessage: issue.comments[0].message,
                        reviewer,
                    }),
                );
            });

            dispatch(
                saveLogsAsync(LogType.submitReview, {
                    inaccuracy,
                    annotator: state.auth.user.username,
                    reviewer: reviewer,
                    status: status,
                }),
            );
            //
        } catch (error) {
            dispatch(reviewActions.submitReviewFailed(error));
        }
    };

export const deleteIssueAsync =
    (id: number): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            review: { frameIssues, activeReview },
            annotation: {
                player: {
                    frame: { number: frameNumber },
                },
            },
        } = state;

        try {
            const [issue] = frameIssues.filter((_issue: any): boolean => _issue.id === id);
            await issue.delete();
            if (activeReview !== null) {
                await activeReview.deleteIssue(id);
                await activeReview.toLocalStorage();
            }
            dispatch(reviewActions.removeIssueSuccess(id, frameNumber));
            dispatch(saveLogsAsync(LogType.removeIssue));
        } catch (error) {
            dispatch(reviewActions.removeIssueFailed(error));
        }
    };
