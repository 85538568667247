// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    LeftOutlined,
    RightOutlined,
    EyeInvisibleFilled,
    EyeOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
} from '@ant-design/icons';
import Alert from 'antd/lib/alert';
import { Row, Col } from 'antd/lib/grid';
import UserSelector, { User } from 'components/task-page/user-selector';

import { changeFrameAsync } from 'actions/annotation-actions';
import { reviewActions } from 'actions/review-actions';
import CVATTooltip from 'components/common/cvat-tooltip';
import { CombinedState } from 'reducers/interfaces';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';

export default function LabelsListComponent(): JSX.Element {
    const dispatch = useDispatch();
    const frame = useSelector((state: CombinedState): number => state.annotation.player.frame.number);
    const frameIssues = useSelector((state: CombinedState): any[] => state.review.frameIssues);
    const issues = useSelector((state: CombinedState): any[] => state.review.issues);
    const reviews = useSelector((state: CombinedState): any[] => state.review.reviews);
    const activeReview = useSelector((state: CombinedState): any => state.review.activeReview);
    const issuesHidden = useSelector((state: CombinedState): any => state.review.issuesHidden);
    const issuesResolvedHidden = useSelector((state: CombinedState): any => state.review.issuesResolvedHidden);
    const keyMap = useSelector((state: CombinedState) => state.shortcuts.keyMap);
    const combinedIssues = activeReview ? issues.concat(activeReview.issues) : issues;
    const frames = combinedIssues.map((issue: any): number => issue.frame).sort((a: number, b: number) => +a - +b);
    const nearestLeft = frames.filter((_frame: number): boolean => _frame < frame).reverse()[0];
    const dinamicLeftProps: any = Number.isInteger(nearestLeft)
        ? {
              onClick: () => dispatch(changeFrameAsync(nearestLeft)),
          }
        : {
              style: {
                  pointerEvents: 'none',
                  opacity: 0.5,
              },
          };

    const nearestRight = frames.filter((_frame: number): boolean => _frame > frame)[0];
    const dinamicRightProps: any = Number.isInteger(nearestRight)
        ? {
              onClick: () => dispatch(changeFrameAsync(nearestRight)),
          }
        : {
              style: {
                  pointerEvents: 'none',
                  opacity: 0.5,
              },
          };

    const [getLatestReviewIssues, setLatestReviewIssues] = useState<boolean>(false);
    const [reviewer, setReviewer] = useState<User | null>(null);
    const [filteredIssues, setFilteredIssues] = useState<any[]>(frameIssues.sort((a:string,b:string) =>  new Date(b.createdDate)- new Date(a.createdDate)));
    useEffect(() => {
        if (reviewer !== null) {
            const filtered = frameIssues.filter((issue: any): boolean => issue.owner.id === reviewer.id);
            setFilteredIssues(filtered);
        } else {
            setFilteredIssues(frameIssues);
        }
        if (getLatestReviewIssues) {
            let latestReviewId: number = 0;
            const sortedReviews = reviews.sort((a: any, b: any): number => a.id - b.id);
            sortedReviews.map((review: any): void => {
                if (reviewer === null) {
                    latestReviewId = review.id;
                } else if (review.reviewer.id === reviewer.id) {
                    latestReviewId = review.id;
                }
            });
            const latestReview = frameIssues.filter((issue: any): boolean => issue.review === latestReviewId);
            setFilteredIssues(latestReview);
        }
    }, [reviewer, getLatestReviewIssues, frameIssues]);

    useEffect(() => {
        const activeIds = filteredIssues.map((issue: any): number => issue.review);
        dispatch(reviewActions.switchIssuesHiddenFilteredFlag(true, activeIds));
    }, [filteredIssues]);
    const handlers = {
        SWITCH_ALL_ISSUES_HIDDEN: () => {
            dispatch(reviewActions.switchIssuesHiddenFlag(!issuesHidden));
        },
        SWITCH_RESOLVED_ISSUES_HIDDEN: () => {
            dispatch(reviewActions.switchIssuesHiddenResolvedFlag(!issuesResolvedHidden));
        },
    };
    const subKeyMap = {
        SWITCH_ALL_ISSUES_HIDDEN: keyMap.SWITCH_ALL_ISSUES_HIDDEN,
        SWITCH_RESOLVED_ISSUES_HIDDEN: keyMap.SWITCH_RESOLVED_ISSUES_HIDDEN,
    };
    return (
        <>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <div className='cvat-objects-sidebar-issues-list-header'>
                <Row justify='start' align='middle'>
                    <Col>
                        <CVATTooltip title='Find the previous frame with issues'>
                            <LeftOutlined className='cvat-issues-sidebar-previous-frame' {...dinamicLeftProps} />
                        </CVATTooltip>
                    </Col>
                    <Col offset={1}>
                        <CVATTooltip title='Find the next frame with issues'>
                            <RightOutlined className='cvat-issues-sidebar-next-frame' {...dinamicRightProps} />
                        </CVATTooltip>
                    </Col>
                    <Col offset={2}>
                        <CVATTooltip title='Show/hide all issues [I]'>
                            {issuesHidden ? (
                                <EyeInvisibleFilled
                                    className='cvat-issues-sidebar-hidden-issues'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenFlag(false))}
                                />
                            ) : (
                                <EyeOutlined
                                    className='cvat-issues-sidebar-shown-issues'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenFlag(true))}
                                />
                            )}
                        </CVATTooltip>
                    </Col>
                    <Col offset={2}>
                        <CVATTooltip title='Show/hide resolved issues [R]'>
                            {issuesResolvedHidden ? (
                                <CheckCircleFilled
                                    className='cvat-issues-sidebar-hidden-resolved-status'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenResolvedFlag(false))}
                                />
                            ) : (
                                <CheckCircleOutlined
                                    className='cvat-issues-sidebar-hidden-resolved-status'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenResolvedFlag(true))}
                                />
                            )}
                        </CVATTooltip>
                    </Col>
                </Row>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'flex-row',
                    alignItems: 'center',
                    padding: '5px',
                    marginLeft: '5px',
                }}
            >
                <span style={{ paddingTop: '2px', marginRight: '10px' }}>Owner</span>
                <div style={{ width: '100px' }}>
                    <UserSelector
                        value={reviewer}
                        onSelect={(e) => {
                            setReviewer(e);
                        }}
                    />
                </div>
                <div
                    onClick={() => setLatestReviewIssues(!getLatestReviewIssues)}
                    style={{ paddingTop: '2px', paddingRight: '10px', marginLeft: '15px', cursor: 'pointer' }}
                >
                    Latest Review
                </div>
                <CVATTooltip title='Get latest review'>
                    {getLatestReviewIssues ? (
                        <CheckCircleFilled
                            className='cvat-issues-sidebar-hidden-resolved-status'
                            onClick={() => setLatestReviewIssues(false)}
                        />
                    ) : (
                        <CheckCircleOutlined
                            className='cvat-issues-sidebar-hidden-resolved-status'
                            onClick={() => setLatestReviewIssues(true)}
                        />
                    )}
                </CVATTooltip>
            </div>
            <div className='cvat-objects-sidebar-issues-list' style={{ paddingBottom: '50px' }}>
                {filteredIssues.map(
                    (frameIssue: any): JSX.Element => (
                        <CVATTooltip
                            key={frameIssue.id}
                            title={
                                'Created: ' +
                                new Date(frameIssue.createdDate).toUTCString().replace('GMT','')
                            }
                        >
                            <div
                                id={`cvat-objects-sidebar-issue-item-${frameIssue.id}`}
                                className='cvat-objects-sidebar-issue-item'
                                onMouseEnter={() => {
                                    const element = window.document.getElementById(
                                        `cvat_canvas_issue_region_${frameIssue.id}`,
                                    );
                                    if (element) {
                                        element.setAttribute('fill', 'url(#cvat_issue_region_pattern_2)');
                                    }
                                }}
                                onMouseLeave={() => {
                                    const element = window.document.getElementById(
                                        `cvat_canvas_issue_region_${frameIssue.id}`,
                                    );
                                    if (element) {
                                        element.setAttribute('fill', 'url(#cvat_issue_region_pattern_1)');
                                    }
                                }}
                            >
                                {frameIssue.resolver ? (
                                    <Alert
                                        description={<span>{`Opened by ${frameIssue.owner.username}`}</span>}
                                        message={`Resolved by ${frameIssue.resolver.username}`}
                                        type='success'
                                        showIcon
                                    />
                                ) : (
                                    <Alert
                                        description={<span>{`By ${frameIssue.owner.username}`}</span>}
                                        message='Opened'
                                        type='warning'
                                        showIcon
                                    />
                                )}
                            </div>
                        </CVATTooltip>
                    ),
                )}
            </div>
        </>
    );
}
