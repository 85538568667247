// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from 'react';
import Form from 'antd/lib/form';
import { SearchOutlined } from '@ant-design/icons';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
const Axios = require('axios');

export default function Search(): JSX.Element {
    const [searchResults, setSearchResults] = useState(null);
    const [frameLinks, setFrameLinks] = useState([]);
    const frame = useSelector((state: CombinedState): number | null => state.tasks.gettingQuery.id);
    const taskId = window.location.href.split('tasks/')[1];

    const onSubmit = async (e): Promise<void> => {
        const searchResult = await Axios.get(`/api/v1/tasks/${frame ?? taskId}/search-frame?q=${e.SearchFile}`);
        setSearchResults(searchResult.data);
    };

    const renderResults = (): JSX.Element => {
        if (searchResults?.length > 0) {
            return searchResults.map((result: { frame: number; job: number }) => (
                <div key={result.frame}>
                    <a target='_blank' href={`/tasks/${taskId}/jobs/${result.job}?frame=${result.frame}`}>
                        Job {result.job} Frame {result.frame}
                    </a>
                </div>
            ));
        } else if (searchResults?.length === 0) {
            return <div>No results</div>;
        }
    };

    useEffect(() => {
        setFrameLinks(renderResults());
    }, [searchResults]);

    return (
        <div style={{ display: 'block', overflow: 'auto' }} className='cvat-search-name'>
            <Text className='cvat-text-color cvat-jobs-header'> Search By File Name </Text>
            <Form style={{ display: 'flex', marginTop: '5px' }} onFinish={onSubmit}>
                <Form.Item hasFeedback name='SearchFile'>
                    <Input
                        prefix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                        placeholder=' File Name'
                    />
                </Form.Item>
                <Form.Item>
                    <Button style={{ width: '70px', marginLeft: '5px' }} type='primary' htmlType='submit'>
                        Search
                    </Button>
                </Form.Item>
            </Form>
            {frameLinks}
        </div>
    );
}
