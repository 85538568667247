// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import Icon, { LockFilled, UnlockOutlined, EyeInvisibleFilled, EyeOutlined } from '@ant-design/icons';

import CVATTooltip from 'components/common/cvat-tooltip';
import { useSelector } from 'react-redux';
import { CombinedState, ShapeType } from 'reducers/interfaces';
import { RectangleIcon, PolygonIcon, PolylineIcon, PointIcon, CubeIcon } from 'icons';
import { AnnotationProperties } from '../../../../utils/enums';
import LabelKeySelectorPopover from './label-key-selector-popover';

interface Props {
    labelName: string;
    labelColor: string;
    labelID: number;
    visible: boolean;
    statesHidden: boolean;
    statesLocked: boolean;
    keyToLabelMapping: Record<string, number>;
    hideStates(): void;
    showStates(): void;
    lockStates(): void;
    unlockStates(): void;
    updateLabelShortcutKey(updatedKey: string, labelID: number): void;
}

function LabelItemComponent(props: Props): JSX.Element {
    const annotations = useSelector(
        (state: CombinedState): any | null => state.annotation.canvas.instance?.view.drawnStates,
    );
    const isReady = useSelector((state: CombinedState): any => state.annotation.canvas.ready);
    const annotationState = useSelector((state: CombinedState): any => state.annotation.annotations.states);
    const [filteredObjects, setFilteredObjects] = useState([{}]);

    useEffect(() => {
        const filtered = [];
        // eslint-disable-next-line guard-for-in
        for (const key in annotations) {
            filtered.push({
                id: annotations[key].label.id,
                type: annotations[key].shapeType,
            });
        }
        setFilteredObjects(filtered);
    }, [isReady, annotationState]);

    // eslint-disable-next-line arrow-body-style
    const getLabelsAnnotationsCount = (targetProperty: any = props.labelID, query?: any): any => {
        return filteredObjects.filter((item: any) => item[targetProperty] === query && item.id === props.labelID)
            .length;
    };

    const iconComponents = {
        [ShapeType.RECTANGLE]: RectangleIcon,
        [ShapeType.POLYGON]: PolygonIcon,
        [ShapeType.POLYLINE]: PolylineIcon,
        [ShapeType.POINTS]: PointIcon,
        [ShapeType.CUBOID]: CubeIcon,
    };

    const renderLabelInfo = (): JSX.Element[] => {
        const columns = [];
        for (const key in ShapeType) {
            if (getLabelsAnnotationsCount(AnnotationProperties.TYPE, ShapeType[key]) !== 0) {
                columns.push(
                    <Col key={key} className='labels-category-container'>
                        <Icon className='cvat-custom-icon-control' component={iconComponents[ShapeType[key]]} />{' '}
                        {getLabelsAnnotationsCount(AnnotationProperties.TYPE, ShapeType[key])}
                    </Col>,
                );
            }
        }
        return columns;
    };

    const {
        labelName,
        labelColor,
        labelID,
        keyToLabelMapping,
        visible,
        statesHidden,
        statesLocked,
        hideStates,
        showStates,
        lockStates,
        unlockStates,
        updateLabelShortcutKey,
    } = props;

    // create reversed mapping just to receive key easily
    const labelToKeyMapping: Record<string, string> = Object.fromEntries(
        Object.entries(keyToLabelMapping).map(([key, _labelID]) => [_labelID, key]),
    );
    const labelShortcutKey = labelToKeyMapping[labelID] || '?';
    const classes = {
        lock: {
            enabled: { className: 'cvat-label-item-button-lock cvat-label-item-button-lock-enabled' },
            disabled: { className: 'cvat-label-item-button-lock' },
        },
        hidden: {
            enabled: { className: 'cvat-label-item-button-hidden cvat-label-item-button-hidden-enabled' },
            disabled: { className: 'cvat-label-item-button-hidden' },
        },
    };

    return (
        <Row
            align='stretch'
            justify='space-around'
            className={[
                'cvat-objects-sidebar-label-item',
                visible ? '' : 'cvat-objects-sidebar-label-item-disabled',
            ].join(' ')}
        >
            <Col span={2}>
                <div style={{ background: labelColor }} className='cvat-label-item-color'>
                    {' '}
                </div>
            </Col>
            <Col span={12}>
                <CVATTooltip title={labelName}>
                    <Text strong className='cvat-text'>
                        {labelName}
                    </Text>
                </CVATTooltip>
            </Col>
            <Col span={3}>
                <LabelKeySelectorPopover
                    keyToLabelMapping={keyToLabelMapping}
                    labelID={labelID}
                    updateLabelShortcutKey={updateLabelShortcutKey}
                >
                    <Button className='cvat-label-item-setup-shortcut-button' size='small' ghost type='dashed'>
                        {labelShortcutKey}
                    </Button>
                </LabelKeySelectorPopover>
            </Col>
            <Col span={2} offset={1}>
                {statesLocked ? (
                    <LockFilled {...classes.lock.enabled} onClick={unlockStates} />
                ) : (
                    <UnlockOutlined {...classes.lock.disabled} onClick={lockStates} />
                )}
            </Col>
            <Col span={3}>
                {statesHidden ? (
                    <EyeInvisibleFilled {...classes.hidden.enabled} onClick={showStates} />
                ) : (
                    <EyeOutlined {...classes.hidden.disabled} onClick={hideStates} />
                )}
            </Col>
            <Row span={12} style={{ width: '95%' }}>
                {renderLabelInfo()}
            </Row>
        </Row>
    );
}

export default React.memo(LabelItemComponent);
