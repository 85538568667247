// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import message from 'antd/lib/message';
import { connect } from 'react-redux';
import { CombinedState, ObjectType } from 'reducers/interfaces';
import {
    rememberObject, updateAnnotationsAsync, removeObjectAsync,
    copyShape as copyShapeAction,
} from 'actions/annotation-actions';
import LabelItemContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/label-item';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import { reviewActions } from 'actions/review-actions';

interface DispatchToProps {
    updateAnnotations(states: any[]): void;
    removeObject: (sessionInstance: any, objectState: any, force: boolean) => void;
    copyShape: (objectState: any) => void;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        updateAnnotations(states: any[]): void {
            dispatch(updateAnnotationsAsync(states));
        },
        removeObject(sessionInstance: any, objectState: any, force: boolean): void {
            dispatch(removeObjectAsync(sessionInstance, objectState, force));
        },
        copyShape(objectState: any): void {
            dispatch(copyShapeAction(objectState));
        },
    };
}

interface StateToProps {
    statesHidden: boolean;
    statesLocked: boolean;
    jobInstance: any;
    objectStates: any[];
    activatedStateID: number | null;
    keyMap: KeyMap;
    normalizedKeyMap: Record<string, string>;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            annotations: {
                states: objectStates,
                activatedStateID,
            },
            job: { instance: jobInstance },
        },
        shortcuts: { keyMap, normalizedKeyMap },
    } = state;

    let statesHidden = true;
    let statesLocked = true;
    objectStates.forEach((objectState: any) => {
        const { lock } = objectState;
        if (!lock) {
            if (objectState.objectType !== ObjectType.TAG) {
                statesHidden = statesHidden && objectState.hidden;
            }
            statesLocked = statesLocked && objectState.lock;
        }
    });

    return {
        statesHidden,
        statesLocked,
        objectStates,
        jobInstance,
        activatedStateID,
        keyMap,
        normalizedKeyMap,
    };
}

type Props = StateToProps & DispatchToProps;

function LabelsListComponent(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const labels = useSelector((state: CombinedState) => state.annotation.job.labels);
    const activatedStateID = useSelector((state: CombinedState) => state.annotation.annotations.activatedStateID);
    const states = useSelector((state: CombinedState) => state.annotation.annotations.states);
    const keyMap = useSelector((state: CombinedState) => state.shortcuts.keyMap);
    const labelIDs = labels.map((label: any): number => label.id);
    const objectStates = useSelector((state: CombinedState) => state.annotation.annotations.states);
    const issuesHidden = useSelector((state: CombinedState): any => state.review.issuesHidden);
    const issuesResolvedHidden = useSelector((state: CombinedState): any => state.review.issuesResolvedHidden);

    const [keyToLabelMapping, setKeyToLabelMapping] = useState<Record<string, number>>(
        Object.fromEntries(labelIDs.slice(0, 10).map((labelID: number, idx: number) => [(idx + 1) % 10, labelID])),
    );

    const updateLabelShortcutKey = useCallback(
        (key: string, labelID: number) => {
            // unassign any keys assigned to the current labels
            const keyToLabelMappingCopy = { ...keyToLabelMapping };
            for (const shortKey of Object.keys(keyToLabelMappingCopy)) {
                if (keyToLabelMappingCopy[shortKey] === labelID) {
                    delete keyToLabelMappingCopy[shortKey];
                }
            }

            if (key === '—') {
                setKeyToLabelMapping(keyToLabelMappingCopy);
                return;
            }

            // check if this key is assigned to another label
            if (key in keyToLabelMappingCopy) {
                // try to find a new key for the other label
                for (let i = 0; i < 10; i++) {
                    const adjustedI = (i + 1) % 10;
                    if (!(adjustedI in keyToLabelMappingCopy)) {
                        keyToLabelMappingCopy[adjustedI] = keyToLabelMappingCopy[key];
                        break;
                    }
                }
                // delete assigning to the other label
                delete keyToLabelMappingCopy[key];
            }

            // assigning to the current label
            keyToLabelMappingCopy[key] = labelID;
            setKeyToLabelMapping(keyToLabelMappingCopy);
        },
        [keyToLabelMapping],
    );

    const {
        removeObject,
        copyShape,
        jobInstance,
        statesHidden,
        statesLocked,
    } = props;

    const activatedStated = (): any | null => {
        if (activatedStateID !== null) {
            const [state] = objectStates.filter(
                (objectState: any): boolean => objectState.clientID === activatedStateID,
            );

            return state || null;
        }

        return null;
    };

    const hideAllStates = (hidden: boolean): void => {
        const { objectStates, updateAnnotations } = props;

        for (const objectState of objectStates) {
            objectState.hidden = hidden;
        }

        updateAnnotations(objectStates);
    }

    const lockAllStates = (locked: boolean): void => {
        const { objectStates, updateAnnotations } = props;
        for (const objectState of objectStates) {
            objectState.lock = locked;
        }
        updateAnnotations(objectStates);
    }

    const preventDefault = (event: KeyboardEvent | undefined): void => {
        if (event) {
            event.preventDefault();
        }
    };

    const handlers = {
        SWITCH_LABEL: (event: KeyboardEvent | undefined, shortcut: string) => {
            if (event) event.preventDefault();
            const labelID = keyToLabelMapping[shortcut.split('+')[1].trim()];
            const label = labels.filter((_label: any) => _label.id === labelID)[0];
            if (Number.isInteger(labelID) && label) {
                if (Number.isInteger(activatedStateID)) {
                    const activatedState = states.filter((state: any) => state.clientID === activatedStateID)[0];
                    if (activatedState) {
                        activatedState.label = label;
                        dispatch(updateAnnotationsAsync([activatedState]));
                    }
                } else {
                    dispatch(rememberObject({ activeLabelID: labelID }));
                    message.destroy();
                    message.success(`Default label was changed to "${label.name}"`);
                }
            }
        },
        DELETE_OBJECT: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            const state = activatedStated();
            if (state) {

                removeObject(jobInstance, state, event ? event.shiftKey : false);
            }
        },
        COPY_SHAPE: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            const state = activatedStated();
            if (state) {
                copyShape(state);
            }
        },
        SWITCH_ALL_HIDDEN: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            hideAllStates(!statesHidden);
        },
        SWITCH_ALL_LOCK: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            lockAllStates(!statesLocked);
        },
        SWITCH_ALL_ISSUES_HIDDEN: () => {
            dispatch(reviewActions.switchIssuesHiddenFlag(!issuesHidden));
        },
        SWITCH_RESOLVED_ISSUES_HIDDEN: () => {
            dispatch(reviewActions.switchIssuesHiddenResolvedFlag(!issuesResolvedHidden));
        },
    };

    const subKeyMap = {
        SWITCH_LABEL: keyMap.SWITCH_LABEL,
        COPY_SHAPE: keyMap.COPY_SHAPE,
        DELETE_OBJECT: keyMap.DELETE_OBJECT,
        SWITCH_ALL_HIDDEN: keyMap.SWITCH_ALL_HIDDEN,
        SWITCH_ALL_LOCK: keyMap.SWITCH_ALL_LOCK,
        SWITCH_ALL_ISSUES_HIDDEN: keyMap.SWITCH_ALL_ISSUES_HIDDEN,
        SWITCH_RESOLVED_ISSUES_HIDDEN: keyMap.SWITCH_RESOLVED_ISSUES_HIDDEN,
    };

    return (
        <div className='cvat-objects-sidebar-labels-list'>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            {labelIDs.map(
                (labelID: number): JSX.Element => (
                    <LabelItemContainer
                        key={labelID}
                        labelID={labelID}
                        keyToLabelMapping={keyToLabelMapping}
                        updateLabelShortcutKey={updateLabelShortcutKey}
                    />
                ),
            )}
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(LabelsListComponent));
